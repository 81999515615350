<template>
  <div class="rootDiv flex flex-dc">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input class="w-450" placeholder="输入关键字进行过滤" v-model="filterText" clearable>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="fs-16 fc-6b9eff pointer" @click="addList()">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
        </div>
      </div>
    </div>
    <div class="menus_tree flex-1 mall-30 overflow-scroll-y">
      <el-tree class="filter-tree" :data="menusData" :props="defaultProps" :filter-node-method="filterNode" ref="tree"
        node-key="id" :expand-on-click-node="true" :default-expanded-keys="defaultExpandedKeys" :accordion="true">
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <div class="flex1 flex flex-jb flex-ac">
            <span class="menus_title">{{ node.label }}{{data.resourceType == 'button'?'（按钮）':''}}</span>
            <span>
              <i class="el-icon-edit icon_col" @click.stop="handleNodeClick(data)"></i>
              <i class="el-icon-circle-plus-outline icon_col" @click.stop="() => addList(data)"
                v-if="data.resourceType == 'menu'"></i>
              <i class="el-icon-remove-outline icon_col" @click.stop="() => remove(data,node)"></i>
            </span>
          </div>
          <!-- <div v-if="data.buttons" class="flex flex-ac mt-10 ml-40">
            <el-tag v-for="tag in data.buttons" :key="tag.id" closable :disable-transitions="false" @close="remove(tag)"
              class="mr-20" @click="handleNodeClick(tag)">
              {{tag.name}}
            </el-tag>
          </div> -->
        </span>
      </el-tree>
    </div>
    <!-- 菜单弹框 -->
    <el-dialog title="新增菜单" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="roleForm" ref="ruleForm" :rules="rules">
        <el-form-item label="资源类型" prop="resourceType" label-width="80px"
          :rules="{required: true, message: '请至少选择一个资源类型', trigger: 'change'}">
          <el-select v-model="roleForm.resourceType" placeholder="请选择">
            <el-option label="菜单" value="menu"></el-option>
            <el-option label="按钮" value="button"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称" prop="name" label-width="80px"
          :rules="{required: true, message: '菜单名称不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="roleForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标" label-width="80px" v-if="roleForm.resourceType != 'button'">
          <el-input type="text" v-model="roleForm.icon" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="请求地址" label-width="80px" v-if="roleForm.resourceType != 'button'">
          <el-input type="text" v-model="roleForm.url" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="排序" label-width="80px" v-if="roleForm.resourceType != 'button'">
          <el-input type="number" v-model="roleForm.sort" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.stop="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click.stop="AddEditMenus">{{roleForm.id?'保 存':'确 定'}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      filterText: '',//关键字
      menusData: [],
      defaultExpandedKeys: [],//默认展开
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      dialogFormVisible: false,
      roleForm: {
        parent: '',  //父级Id
        icon: '',   //图标
        sort: '',   //排序
        name: '',   //菜单名字
        resourceType: '',  //资源类型
        url: '',  //请求地址
      },
      rules: {  //菜单校验
        resourceType: [
          { required: true, message: '请选择资源类型', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ]
      },
    }
  },
  watch: {
    filterText (val) {
      console.log(val)
      this.$refs.tree.filter(val)
    }
  },
  mounted () {
    this.getMenus()
  },
  methods: {
    //获取菜单数据
    getMenus () {
      this.loading = true
      const each = (list) => {
        list.forEach(element => {
          if (element.resourceType != "button") {
            if (element.buttons) {
              element.children = element.children.concat(element.buttons)
            }
            if (element.children.length > 0) {
              each(element.children)
            }
          }
        })
      }
      this.$http.get('/permission/findPermissionList').then(({ data: result }) => {
        this.loading = false
        let menusData = result.data
        this.$store.commit('menu/SET_MENU', result.data) //刷洗右侧导航栏 vuex
        each(menusData)
        this.menusData = menusData
        this.defaultExpandedKeys = [this.roleForm.parent]
      })
    },
    //新增弹框打开
    addList (data) {
      let roleForm = {
        parent: '',
        icon: '',
        sort: '',
        name: '',
        resourceType: 'menu',
        url: '',
      }
      console.log(data, '选择数据')
      if (data) {
        roleForm.parent = data.id //父级id
        if (data.children.length == 0) {  //排序
          roleForm.sort = 1
        } else {
          roleForm.sort = data.children[data.children.length - 1].sort + 1
        }
      } else {
        let menusData = this.menusData
        if (menusData.length == 0) {  //排序
          roleForm.sort = 1
        } else {
          roleForm.sort = menusData[menusData.length - 1].sort + 1
        }
      }
      this.roleForm = roleForm
      this.dialogFormVisible = true
    },
    //弹框确认
    AddEditMenus () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.roleForm.id) {
            this.editMenus()
          } else {
            this.append()
          }
        }
      })
    },
    //新增
    append () {
      this.$http.post('/permission/addPermission', this.roleForm).then(({ data: result }) => {
        console.log(result, '新增')
        this.getMenus()
        this.dialogFormVisible = false
        this.$message({
          message: '新增成功',
          type: 'success'
        })
        // this.$router.go(0)
      })
    },
    //修改
    editMenus () {
      this.$http.post('/permission/editPermission', this.roleForm).then(({ data: result }) => {
        this.getMenus()
        this.dialogFormVisible = false
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        // this.$router.go(0)
      })
    },
    //删除
    remove (data) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/permission/delePermission', {
          id: data.id
        }).then(({ data: result }) => {
          this.getMenus()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
      // const index = children.findIndex(d => d.id === data.id)  //获取对应条件的索引位置
      // children.splice(index, 1)
    },
    //查看详情
    handleNodeClick (data) {
      this.roleForm = JSON.parse(JSON.stringify(data))
      this.dialogFormVisible = true
    },
    //导出
    exportExcel () { },
    //树形结构检索赛选
    filterNode (value, data) {
      console.log(data)
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
  }
}
</script>

<style lang="less" scoped>
.menus_tree {
  background: #fff;
  /deep/.el-tree-node__content {
    // height: 60px !important;
    padding: 10px;
    height: auto !important;
    align-items: start;
  }
  .custom-tree-node {
    font-size: 18px;
    padding-right: 8px;
    .menus_title {
      display: inline-block;
      width: 1000px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .icon_col {
      color: #5cb6ff;
      margin-left: 20px;
    }
  }
}
</style>